import { createStore, createLogger } from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

var ls = new SecureLS({ isCompression: false });

import state from "./states";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

function safeGetItem(key) {
  try {
    return ls.get(key) ? JSON.parse(ls.get(key)) : null;
  } catch (error) {
    console.error(`Error parsing SecureLS data for key: ${key}`, error);
    ls.remove(key); // Remove corrupted data
    return null;
  }
}

// Create a new store instance.
export default createStore({
  state,
  getters,
  mutations,
  actions,
  modules: {},
  plugins: process.env.NODE_ENV !== "production"
    ? [
        createPersistedState({
          storage: {
            getItem: key => safeGetItem(key),  // Use safeGetItem
            setItem: (key, value) => ls.set(key, JSON.stringify(value)),
            removeItem: key => ls.remove(key)
          },
          paths: ["lan", "auth", "setting"]
        }),
        createLogger()
      ]
    : [
        createPersistedState({
          storage: {
            getItem: key => safeGetItem(key),  // Use safeGetItem
            setItem: (key, value) => ls.set(key, JSON.stringify(value)),
            removeItem: key => ls.remove(key)
          },
          paths: ["lan", "auth", "setting"]
        })
      ]
});
